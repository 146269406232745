import { useEffect } from "react";
import { KEYS } from "../../common/constants";
import styles from "./index.module.css";

function Keyboard({ insertLetter, deleteLetter, checkGuess, keyboardColors }) {
  useEffect(() => {
    document.addEventListener("keyup", (e) => {
      // if (guessesRemaining === 0) return;

      let pressedKey = String(e.key);
      pressKey(pressedKey);
    });
  }, []);

  const pressKey = (pressedKey) => {
    console.log(pressedKey);
    if (
      pressedKey.toLowerCase() === "backspace" ||
      pressedKey.toLowerCase() === "del" ||
      pressedKey.toLowerCase() === "delete"
    ) {
      deleteLetter();
      return;
    }

    if (pressedKey.toLowerCase() === "enter") {
      checkGuess();
      return;
    }

    if(pressedKey.length !== 1) return;

    let found = pressedKey.match(/[a-z]/gi);
    if (!found || found.length > 1) {
      return;
    } else {
      insertLetter(pressedKey);
    }
  };

  return (
    <div id="keyboard-cont" className={styles.keyboardCont}>
      <div className={styles.firstRow}>
        {KEYS.row1.map((key) => (
          <button
            key={key}
            className={styles.keyboardButton}
            style={{ backgroundColor: keyboardColors[key] || "none" }}
            onClick={() => pressKey(key)}
          >
            {key}
          </button>
        ))}
      </div>
      <div className={styles.secondRow}>
        {KEYS.row2.map((key) => (
          <button
            key={key}
            className={styles.keyboardButton}
            style={{ backgroundColor: keyboardColors[key] || "none" }}
            onClick={() => pressKey(key)}
          >
            {key}
          </button>
        ))}
      </div>
      <div className={styles.thirdRow}>
        {KEYS.row3.map((key) => (
          <button
            key={key}
            className={styles.keyboardButton}
            style={{ backgroundColor: keyboardColors[key] || "none" }}
            onClick={() => pressKey(key)}
          >
            {key}
          </button>
        ))}
      </div>
    </div>
  );
}

export default Keyboard;
