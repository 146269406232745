import WordleGame from "../WordleGame";
import styles from "./index.module.css";

function Home() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      <h1 className={styles.header}>WORDLE BEAT</h1>
      <WordleGame />
    </div>
  );
}

export default Home;
