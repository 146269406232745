export const NUMBER_OF_GUESSES = 6;

export const COLORS = {
  GRAY: "#343434",
  YELLOW: "#b59f3b",
  GREEN: "#538d4e",
};

export const KEYS = {
  row1: ["q", "w", "e", "r", "t", "y", "u", "i", "o", "p"],
  row2: ["a", "s", "d", "f", "g", "h", "j", "k", "l"],
  row3: ["Del", "z", "x", "c", "v", "b", "n", "m", "Enter"],
};
