import { keyboard } from "@testing-library/user-event/dist/keyboard";
import { useEffect, useRef, useState } from "react";
import { COLORS, NUMBER_OF_GUESSES } from "../../common/constants";
import { WORDS } from "../../common/words";
import Keyboard from "../Keyboard";

import styles from "./index.module.css";

function Game() {
  const [guessedWords, setGuessedWords] = useState([""]);
  const [keyboardColors, setKeyboardColors] = useState({});
  const isSolvedRef = useRef(false);
  const hasLostRef = useRef(false);
  const correctWordRef = useRef();
  const guessedWordsRef = useRef([]);
  const keyboardColorsRef = useRef({});
  const animatedLetterRef = useRef(-1);

  guessedWordsRef.current = guessedWords;
  keyboardColorsRef.current = keyboardColors;

  useEffect(() => {
    correctWordRef.current = WORDS[Math.floor(Math.random() * WORDS.length)];
  }, []);

  const insertLetter = (pressedKey) => {
    let guessedWordsRefCurr = guessedWordsRef.current;

    // If game is solved or if we have used up all our guesses
    if (isSolvedRef.current || hasLostRef.current) return;

    // Check if all 5 characters have been entered?
    let lastWord = guessedWordsRefCurr[guessedWordsRefCurr.length - 1] || "";
    if (lastWord.length === 5) return;

    // Read pressed key and add to end of last word
    pressedKey = pressedKey.toLowerCase();
    lastWord += pressedKey;

    // Update
    let tempGuesses = [...guessedWordsRefCurr];
    if (tempGuesses.length === 0) tempGuesses.push(lastWord);
    else tempGuesses[tempGuesses.length - 1] = lastWord;
    setGuessedWords(tempGuesses);
  };

  const deleteLetter = () => {
    let guessedWordsRefCurr = guessedWordsRef.current;

    // If game is solved or if we have used up all our guesses
    if (isSolvedRef.current || hasLostRef.current) return;

    // Check if 0 characters have been entered?
    let lastWord = guessedWordsRefCurr[guessedWordsRefCurr.length - 1] || "";
    if (lastWord.length === 0) return;

    lastWord = lastWord.slice(0, -1);
    console.log("lastWord: " + lastWord);
    let tempGuesses = [...guessedWordsRefCurr];
    if (tempGuesses.length > 0) tempGuesses[tempGuesses.length - 1] = lastWord;
    setGuessedWords(tempGuesses);
  };

  function checkGuess() {
    let currGuessedWords = guessedWordsRef.current;

    // If game is solved or if we have used up all our guesses
    if (isSolvedRef.current || hasLostRef.current) return;

    let guessString = currGuessedWords[currGuessedWords.length - 1] || "";

    console.log("guessString.length: " + guessString.length);

    if (guessString.length !== 5) {
      alert("Not enough letters! " + guessString);
      return;
    }

    if (!WORDS.includes(guessString)) {
      alert("Word not in list!");
      return;
    }

    /* let delay = 250 * i
        setTimeout(()=> {
            //shade box
            box.style.backgroundColor = letterColor
            shadeKeyBoard(letter, letterColor)
        }, delay)
    }*/

    console.log("guessString: " + guessString);
    console.log("correctWord: " + correctWordRef.current);
    console.log(
      "guessString === correctWord: " + (guessString === correctWordRef.current)
    );
    console.log(
      "guessString.localeCompare(correctWord): " +
        guessString.localeCompare(correctWordRef.current)
    );

    if (guessString.localeCompare(correctWordRef.current) === 0) {
      alert("You guessed right! Game over!");
      isSolvedRef.current = true;
      // return;
    } else {
      if (currGuessedWords.length === NUMBER_OF_GUESSES) {
        alert("You've run out of guesses! Game over!");
        alert(`The right word was: "${correctWordRef.current}"`);
        hasLostRef.current = true;
      }
    }
    setGuessedWords([...currGuessedWords, ""]);
    animatedLetterRef.current = 0;
  }

  const calculateBoxColor = (guess, index) => {
    let letter = guess && guess[index];
    let boxColor = "none";

    if (!letter) return boxColor;

    // Letter appears in correct word?
    if (correctWordRef.current.includes(letter)) {
      // Letter matches one in correct word?
      if (correctWordRef.current[index] === letter) {
        boxColor = COLORS.GREEN;
      } else {
        // Letter does not match in correct word at index?
        let yellowInCorrectCount = 0;
        let yellowInGuessCount = 0;
        let yellowBeforeGuessLetterCount = 0;

        for (let i = 0; i < 5; i++) {
          // Skip letter occurances which are green, i.e. in correct place
          if (correctWordRef.current[i] === letter && guess[i] === letter)
            continue;

          // Find number of misplaced letter occurrences in correct word
          if (correctWordRef.current[i] === letter) yellowInCorrectCount++;

          // Find number of misplaced letter occurrences in guess word
          if (guess[i] === letter) {
            yellowInGuessCount++;
            // Also find those which occur before index
            if (i < index) yellowBeforeGuessLetterCount++;
          }
        }

        // If number of times letter occurs in correct word(non-green) is more than that in guess word
        if (yellowInCorrectCount >= yellowInGuessCount) {
          boxColor = COLORS.YELLOW;
        } else {
          if (yellowBeforeGuessLetterCount >= yellowInCorrectCount) {
            // We have already shown all the yellow characters?
            boxColor = COLORS.GRAY;
          } else {
            boxColor = COLORS.YELLOW;
          }
        }
      }
    } else {
      // Letter does not appear in correct word
      boxColor = COLORS.GRAY;
    }

    if(boxColor !== "none")
    {
      if(keyboardColorsRef.current[letter])
      {
        // If it is yellow, overwrite if new color is green
        if(keyboardColorsRef.current[letter] === COLORS.YELLOW)
          keyboardColorsRef.current[letter] = boxColor;
      }
      else
      {
        // No color set for letter till now
        keyboardColorsRef.current[letter] = boxColor;
      }
    }

    return boxColor;
  };

  const generateWordGrid = (guesses) => {
    let rows = [];

    for (let i = 0; i < NUMBER_OF_GUESSES; i++) {
      let rowBoxes = [];
      for (let j = 0; j < 5; j++) {
        let boxColor = "none";
        if (i < guesses.length - 1) {
          boxColor = calculateBoxColor(guesses[i], j);
        }
        // let letter = guesses[i] && guesses[i][j];
        // if (letter && i < guesses.length - 1) {
        //   if (correctWordRef.current.includes(letter)) {
        //     if (correctWordRef.current[j] === letter) {
        //       boxColor = COLORS.GREEN;
        //     } else {
        //       boxColor = COLORS.YELLOW;
        //     }
        //   } else {
        //     boxColor = COLORS.GRAY;
        //   }
        // }
        rowBoxes.push(
          <div
            key={i * 10 + j}
            className={styles.letterBox}
            style={{ backgroundColor: boxColor }}
          >
            {guesses[i] && guesses[i][j]}
          </div>
        );
      }
      rows.push(
        <div key={i * 100} className={styles.letterRow}>
          {rowBoxes}
        </div>
      );
    }

    return rows;
  };

  return (
    <>
      <div id="game-board" className={styles.gameBoard}>
        {generateWordGrid(guessedWords)}
      </div>
      <Keyboard
        insertLetter={insertLetter}
        deleteLetter={deleteLetter}
        checkGuess={checkGuess}
        keyboardColors={keyboardColors}
      />
      {/* <div>{guessedWords}</div>
      <div>{correctWordRef.current}</div> */}
    </>
  );
}

export default Game;
